import { useEffect } from "react";

const SimplexWidget = ({ state }) => {
  useEffect(() => {
    const public_key = import.meta.env.VITE_REACT_SIMPLEX_PUBLIC_KEY;
    const wallet_id = import.meta.env.VITE_REACT_SIMPLEX_WALLET_ID;
    const payment_id = window.uuid;
    const quote_id = window.quote_id;

    // window.simplexAsyncFunction = function () {
    //   Simplex.init({ public_key: public_key });
    // };

    // Динамічно додаємо скрипт Simplex до сторінки
    const script = document.createElement("script");
    script.src = "https://cdn.test-simplexcc.com/sdk/v1/js/sdk.js";
    script.async = true;
    script.onload = () => {
      // window.simplexAsyncFunction();
      Simplex.init({ public_key });

      setTimeout(() => {
        if (window.Simplex) {
          window.Simplex.load({
            payment_id: window.uuid
          }, function (event) {
              console.log(event.type) // => 'checkoutLoadSucceeded'
              if (event.type === "onlineFlowFinished") {
                state.setSimplexWidget(false);
              }
          });
          return;
        }
      }, 200);
    };
    document.body.appendChild(script);

    // Очищення скрипта після демонтажу компоненту
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div id="simplex-widget-container"></div>
      <form id="simplex-form">
        <div id="checkout-element"></div>
      </form>
    </div>
  );
};

export default SimplexWidget;
