import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI.js";
import Providers from "../providers/Providers.jsx";
import React from "react";

/**
 *
 * @param {{
 * data: { validProviders: import("./types.js").CurrencyProviderRange[], currency: import("./types.js").Currency, amount: string },
 * selected: import("./types.js").BestRate | undefined,
 * onPay: (arg0: { quote_id: string, response: { message: "Payment initialized" } }) => void
 * back: () => void
 * }} param0
 * @returns
 */
export function ProvidersControlled({
  data: { validProviders, currency, amount },
  selected,
  onPay,
  back,
}) {
  if (validProviders.length === 0) {
    throw new Error("Should be called only with valid providers");
  }
  const axiosClient = useAxiosWithAuth();
  const rates = useSWR(
    () => [
      "cashier/fiat/estimated-exchange-result-for-all-providers",
      currency.code,
      amount,
      validProviders.map(({ provider }) => provider),
    ],
    ([url, currency, amount, providers]) =>
      axiosClient.wrap(
        axiosClient.get(url, {
          params: {
            fromCurrencyAlpha3: currency,
            fromCurrencyAmount: amount,
            providers,
          },
        })
      ),
    {
      fallbackData: selected ? [selected] : [],
      refreshInterval: 25000,
    }
  );

  const pay = useSWRMutation(
    [`cashier/fiat/providers`],
    ([url], { arg: { quote_id, provider } }) =>
      axiosClient.wrap(
        axiosClient.post(`${url}/${provider}/create-payment-session`, {
          paymentUuid: window.uuid,
          quoteId: quote_id,
        })
      ),
    {
      onSuccess: (data, key, config) => {
        console.log("pay onSuccess", data, key, config);
      },
    }
  );

  const toConfirm = async ({ provider, response: { quoteId } }) => {
    // const response = {
    //   message: "Payment initialized",
    // };
    try {
      /**
       * @type {{ message?: "Payment initialized" }}
       */
      const response = await pay.trigger({
        quote_id: quoteId,
        provider,
      });

      if (response.message) {
        console.debug("toConfirm", response);
        onPay({ quote_id: quoteId, response });
      }
    } catch (error) {
      console.error("toConfirm", error);
    }
  };
  if (rates.isLoading) {
    if (rates.data) {
      return (
        <Providers
          providers_data={rates.data}
          selected={selected}
          back={back}
          toConfirm={toConfirm}
          getProviders={() => {
            rates.mutate();
          }}
        />
      );
    }
    // initial state should be set
    // if u see loading => it's a bug
    return <div>Loading...</div>;
  }
  if (rates.error) {
    console.error(rates.error);
    return <div>Error...</div>;
  }

  return (
    <Providers
      providers_data={rates.data}
      selected={selected}
      back={back}
      toConfirm={toConfirm}
      getProviders={() => {
        rates.mutate();
      }}
    >
      {pay.error && ((typeof pay.error === 'object') ? pay.error.message : pay.error)}
    </Providers>
  );
}
