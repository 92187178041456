import React, { useContext, useEffect, useState } from "react";
import WalletLogin from "./wallet-auth/WalletLogin";
import { Web3Provider } from "/context/Web3Provider";

import useForm from "/utils/hooks/useForm";
import CustomButton from "/components/core/ButtonDefault";
import FormLabels from "/components/core/labels/Form";
import AuthContext from "/context/AuthProvider";
import { useTranslation } from "react-i18next";
import { useUserCache } from "/utils/hooks/useUserCache";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export default function Login({ state }) {
  const isWalletConnectActive = useFeatureIsOn("can-use-auth-with-wallet");
  const useform = useForm({}, "auth/login");
  const { isSuccess, isSending, formResponse, setInvalidFields, handleSubmit } =
    useform;
  const { setAuth } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { wipe } = useUserCache();

  const labels = [
    {
      placeholder: t("registration.labels.email.placeholder"),
      type: "email",
      name: "email",
      spaces: true,
    },
    {
      placeholder: t("registration.labels.password.placeholder"),
      type: "password",
      name: "password",
    },
    {
      title: t("labels.rememberMe"),
      type: "checkbox",
      name: "remember",
    },
  ];

  useEffect(() => {
    if (!isSuccess) return;
    setAuth({ ...formResponse });
    state.setPopUp("");
    return () => {};
  }, [isSuccess]);

  useEffect(() => {
    const err = formResponse?.errors;

    if (err && Array.isArray(err)) {
      setInvalidFields((e) => ({ ...e, email: err[0] }));
    } else {
      const s = formResponse?.success;
      const m = formResponse?.message;
      !s && m && setError(m);
    }
    return () => {};
  }, [formResponse]);

  const performSubmit = (e) => {
    e.preventDefault();
    wipe();
    return handleSubmit(e);
  };

  return (
    <>
      <div className="title">
        <p className="t fixel_36">{t("labels.signIn")}</p>
      </div>

      <div className="content_data df fd-c ai-c">
        {isWalletConnectActive ? (
          <>
            <Web3Provider>
              <WalletLogin />
            </Web3Provider>
            <div className="or df ai-c fixel_16_sbl">{t("labels.or")}</div>
          </>
        ) : null}
        <form className="form df fd-c" onSubmit={performSubmit}>
          <div className="form_content">
            {error && (
              <p className="fixel_22 text-center color-red w-full">{error}</p>
            )}
            <FormLabels {...useform} labels={labels} />

            <button
              type="button"
              className="def wt"
              onClick={(e) => state.setPopUp("reset")}
            >
              {t("message.forgotPassword")}
            </button>
          </div>
          <CustomButton
            classname="submit pr as-c"
            def={40}
            type="submit"
            title={t("labels.signIn")}
            disabled={isSending}
          />
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">{t("registration.notMember")}</p>
        <button className="def wt" onClick={(e) => state.setPopUp("reg")}>
          {t("login.signUpNow")}
        </button>
      </div>
    </>
  );
}
