import React from "react";
import { useTranslation } from "react-i18next";
import { useRankAndSeason } from "/api/user/rank-n-season";

export default function UserRanks({ myref }) {
  const { t } = useTranslation();
  const { data } = useRankAndSeason();

  if (!data?.rank || !data?.season) {
    return null;
  }

  const { rank, season } = data;
  const seasonData = season.data;

  return (
    <div className="grid-container fixel_14" ref={myref}>
      {/* Current rank */}
      <div className="t">{t("layout.header.rankTooltip.current")}</div>
      <div className="v fixel_14_sbl df fd-c">
        <div className="df ai-c">
          {rank?.current?.name}
          {rank?.current?.iconUrl && (
            <div className="ico to df">
              <img src={rank?.current.iconUrl} alt={rank?.current?.name} />
            </div>
          )}
        </div>
      </div>

      {/* Next rank */}
      {rank && !rank.isLastRank && (
        <>
          <div className="t">{t("layout.header.rankTooltip.next")}</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {rank?.next?.name}
              {rank?.next?.iconUrl && (
                <div className="ico to df">
                  <img src={rank?.next.iconUrl} alt={rank?.next?.name} />
                </div>
              )}
            </div>
            <div className="df ai-c gap-4">
              {rank?.currentXp} <span className="color-neu"> / </span>{" "}
              {rank?.next.requiredXpPoints}
              <div className="icon_xp xs"></div>
            </div>
            <div className="need df ai-c gap-4">
              {rank?.next.requiredXpPoints - rank?.currentXp}{" "}
              <div className="icon_xp xs"></div>{" "}
              <span>{t("layout.header.rankTooltip.needed")}</span>
            </div>
          </div>
        </>
      )}

      {/* Next rank level */}
      {rank && !rank.isLastRankAndLastLvl && (
        <>
          <div className="t">{t("layout.header.rankTooltip.nextRankLv")}</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {rank?.currentXp} <span className="color-neu"> / </span>{" "}
              {rank?.nextLevelRequiredXpPoints}
              <div className="icon_xp xs"></div>
            </div>
            <div className="need df ai-c gap-4">
              {rank?.expToNextLvl} <div className="icon_xp xs"></div>{" "}
              <span>{t("layout.header.rankTooltip.needed")}</span>
            </div>
          </div>
        </>
      )}

      {/* Seasons */}
      {seasonData && seasonData.currentLevel && (
        <>
          <div className="line w-full"></div>
          <div className="t">{t("labels.season")}</div>
          <div className="v fixel_14_sbl df fd-c">
            {seasonData.season?.name}
          </div>

          <div className="t">{t("layout.header.rankTooltip.level")}</div>
          <div className="v fixel_14_sbl df fd-c">
            {seasonData.currentLevel?.lvl}
          </div>

          <div className="t">{t("layout.header.rankTooltip.progress")}</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {seasonData.currentXp}
              <span className="color-neu"> / </span>{" "}
              {seasonData.nextLevel?.requiredXpPoints}
              <div className="icon_xp xs"></div>
            </div>
            {seasonData.nextLevel && (
              <div className="need df ai-c gap-4">
                {seasonData.nextLevel.requiredXpPoints - seasonData.currentXp}{" "}
                <div className="icon_xp xs"></div>{" "}
                <span>{t("layout.header.rankTooltip.needed")}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
