import * as styles from "./Banner.module.scss";
import Mask1 from "/assets/img/promotion/mask-1.svg";
import Mask2 from "/assets/img/promotion/mask-2.svg";
import Mask3 from "/assets/img/promotion/mask-3.svg";
import Mask4 from "/assets/img/promotion/mask-4.svg";
import TextMobile from "/assets/img/promotion/text-m.svg";
import TextDesktop from "/assets/img/promotion/text.svg";

export default function PromoBanner({ state }) {
  const mobile = state.getState().isMobile;
  const openModal = () => {
    state.setPopUp("reg");
  };

  return (
    <div className={styles.banner} role="button" onClick={openModal}>
      <div className={styles.bg}>
        <Mask mask={<Mask1 />} />
        <Mask mask={<Mask3 />} />
        <Mask mask={<Mask2 />} />
        <Mask mask={<Mask4 />} />
      </div>
      {mobile ? (
        <div className={styles.wrapper}>
          <TextMobile />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <TextDesktop />
        </div>
      )}
    </div>
  );
}

const Mask = ({ mask }) => {
  return <div className={styles.mask}>{mask}</div>;
};
