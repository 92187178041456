import React, { createContext, useState } from "react";
import { useEffect } from "react";

/**
 * @typedef {Object} AuthState
 * @property {string|null} token - Authentication token
 * @property {Object} [cursor] - Cursor configuration
 * @property {string} [cursor.defaultStateUrl] - Default cursor state URL
 * @property {string} [cursor.hoverStateUrl] - Hover cursor state URL
 */

/**
 * @typedef {Object} AuthContextType
 * @property {AuthState} auth - The authentication state
 * @property {function(AuthState): void} setAuth - Function to update the authentication state
 */

/** @type {React.Context<AuthContextType>} */
const AuthContext = createContext({
  auth: { token: null },
  setAuth: () => {},
});

const initialState = {
  token: null,
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => {
    // Load auth state from localStorage if it exists
    const authData = localStorage.getItem("auth");

    return JSON.parse(authData) || initialState;
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify({ ...auth }));

    const body = document.documentElement;
    const cursor = auth?.cursor?.defaultStateUrl;
    const hover = auth?.cursor?.hoverStateUrl;
    body.style.setProperty(
      "--def-cursor",
      cursor ? `url("${cursor}"), default` : "default"
    );
    body.style.setProperty(
      "--def-hover-cursor",
      hover ? `url("${hover}"), pointer` : "pointer"
    );

    return () => {};
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
