import useSWR, { useSWRConfig } from "swr";
/**
 *
 * @return {null | string | undefined} Tico balance
 */
export function useCashedTicoBalance() {
  return useSWRConfig().cache.get("/user/tico-balance")?.data;
}
/**
 * @typedef {{ balance: string, withdrawableBalance: string, blockedBalance: string, ticoBalance: string }} Body
 * @typedef {{ }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr").SWRConfiguration<Body, Error, import("swr").BareFetcher<Body>>} config
 * @return {import("swr").SWRResponse<Body, Error>}
 */
export function useWithdrawableBalance(axiosClient, config = {}) {
  return useSWR(
    () => ["user/tico-balance"],
    ([url]) => axiosClient.get(url).then((res) => res.data),
    {
      keepPreviousData: true,
      ...config,
    }
  );
}
